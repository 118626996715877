:root {
  --standard-padding: 8px;
  --teamkey-standard-margin: 8px;
  --light-grey: #f3f3f3;
  --white: #ffffff;
  --grey: #4b5865;
  --teamkey-grey: #242c35;
  --blue: #007fdf;
  --light-blue: #bcdfff;

  --z-index-mobile-menu: 10;
}

$teamkey-standard-padding: var(--standard-padding);
$teamkey-standard-margin: var(--teamkey-standard-margin);
$light-grey: var(--light-grey);
$white: var(--white);
$blue: var(--blue);
$light-blue: var(--light-blue);
