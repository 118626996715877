$breakpoint-sm: 600px;
$breakpoint-md: 905px;
$breakpoint-lg: 1240px;
$breakpoint-xlg: 1440px;
$breakpoint-xxlg: 1920px;

@mixin responsive-grid {
    --grid-columns: 4;
    --grid-gap: 16px;
    --grid-vertical-margin: 16px;
    --grid-horizontal-margin: 16px;
    --grid-template-rows: auto;
    
    display: grid;
    grid-template: var(--grid-template-rows) / repeat(var(--grid-columns), minmax(0, 1fr));
    gap: var(--grid-gap);
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: var(--grid-vertical-margin) var(--grid-horizontal-margin);

    ::ng-deep {
        @for $i from 1 through 4 {
            .xs-#{$i} {
                grid-column-end: span #{$i};
            }

            &.xs-#{$i} {
                --grid-columns: #{$i};
                --grid-vertical-margin: 0;
                --grid-horizontal-margin: 0;
            }
        }

        @include for-xs {
            .xs-hidden {
                display: none;
            }
        }
    }

    &.full-width {
        grid-column-end: span 4;
        --grid-vertical-margin: 0;
        --grid-horizontal-margin: 0;
    }

    @include from-sm {
        --grid-columns: 8;
        --grid-horizontal-margin: 32px;

        ::ng-deep {
            @for $i from 1 through 8 {
                .sm-#{$i} {
                    grid-column-end: span #{$i};
                }

                &.sm-#{$i} {
                    --grid-columns: #{$i};
                    --grid-vertical-margin: 0;
                    --grid-horizontal-margin: 0;
                }
            }
        }

        &.full-width {
            grid-column-end: span 8;
        }
    }

    @include from-md {
        --grid-columns: 12;

        ::ng-deep {
            @for $i from 1 through 12 {
                .md-#{$i} {
                    grid-column-end: span #{$i};
                }

                &.md-#{$i} {
                    --grid-columns: #{$i};
                    --grid-vertical-margin: 0;
                    --grid-horizontal-margin: 0;
                }
            }
        }

        &.full-width {
            grid-column-end: span 12;
        }
    }

    @include from-lg {
        // --grid-columns: 16;
        ::ng-deep {
            @for $i from 1 through 12 {
                .lg-#{$i} {
                    grid-column-end: span #{$i};
                }

                &.lg-#{$i} {
                    --grid-columns: #{$i};
                    --grid-vertical-margin: 0;
                    --grid-horizontal-margin: 0;
                }
            }
        }
    }

    @include from-xlg {
        // --grid-columns: 16;
        ::ng-deep {
            @for $i from 1 through 12 {
                .xlg-#{$i} {
                    grid-column-end: span #{$i};
                }

                &.xlg-#{$i} {
                    --grid-columns: #{$i};
                    --grid-vertical-margin: 0;
                    --grid-horizontal-margin: 0;
                }
            }
        }
    }

    @include from-xxlg {
        // --grid-horizontal-margin: calc((100% - 1920px) / 2);
        ::ng-deep {
            @for $i from 1 through 12 {
                .xxlg-#{$i} {
                    grid-column-end: span #{$i};
                }

                &.xxlg-#{$i} {
                    --grid-columns: #{$i};
                    --grid-vertical-margin: 0;
                    --grid-horizontal-margin: 0;
                }
            }
        }
    }
}

@mixin for-xs {
    @media screen and (max-width: ($breakpoint-sm - 1px)) {
        @content;
    }
}

@mixin from-sm {
    @media screen and (min-width: $breakpoint-sm) {
        @content;
    }
}

@mixin from-md {
    @media screen and (min-width: $breakpoint-md) {
        @content;
    }
}

@mixin from-lg {
    @media screen and (min-width: $breakpoint-lg) {
        @content;
    }
}

@mixin from-xlg {
    @media screen and (min-width: $breakpoint-xlg) {
        @content;
    }
}

@mixin from-xxlg {
    @media screen and (min-width: $breakpoint-xxlg) {
        @content;
    }
}
