$enable-grid-classes: false;
$enable-cssgrid: true;
$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 905px,
    lg: 1240px,
    xl: 1440px,
    xxl: 1920px,
);

$primary: #bcdfff;
$secondary: #ffffff;

@import "../../node_modules/bootstrap/scss/bootstrap";

.popover-header {
    margin-bottom: 0 !important;
}

.btn-secondary {
    border: 1px solid var(--teamkey-grey);

    &:hover, &:focus {
        border: 1px solid var(--teamkey-grey);
    }
}