/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "theme/material";
@import "mixins";

body {
    background: black;
}

ion-app {
    max-width: 1920px;
    margin: 0 auto;

    &.ion-page {
        background: var(--light-grey);
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold !important;
}

.grid {
    display: grid;
    grid-template-columns: repeat(var(--bs-columns, 12), minmax(0, 1fr));

    &.main {
        --bs-columns: 4;

        width: 100%;
        min-height: 100%;

        padding: 16px;

        @include from-sm {
            --bs-columns: 8;

            padding: 16px 32px;
        }

        @include from-md {
            --bs-columns: 12;
        }
    }

    //
    @for $i from 1 through 4 {
        &.g-col-#{$i} {
            --bs-columns: #{$i};
        }
    }

    @include for-xs {
        .xs-hidden {
            display: none;
        }
    }

    @include from-sm {
        @for $i from 1 through 8 {
            &.g-col-sm-#{$i} {
                --bs-columns: #{$i};
            }
        }
    }

    @include from-md {
        @for $i from 1 through 12 {
            &.g-col-md-#{$i} {
                --bs-columns: #{$i};
            }
        }
    }

    @include from-lg {
        @for $i from 1 through 12 {
            &.g-col-lg-#{$i} {
                --bs-columns: #{$i};
            }
        }
    }

    @include from-xlg {
        @for $i from 1 through 12 {
            &.g-col-xl-#{$i} {
                --bs-columns: #{$i};
            }
        }
    }

    @include from-xxlg {
        @for $i from 1 through 12 {
            &.g-col-xxl-#{$i} {
                --bs-columns: #{$i};
            }
        }
    }
}

.d-flex, .d-sm-flex, .d-md-flex, .d-lg-flex, .d-xl-flex, .d-xxl-flex {
    gap: var(--standard-padding);
}

.message {
    padding: 8px;
    border-radius: 10px;

    &.error {
        background: red;
        color: white;
        margin-bottom: 22px;;
    }
}