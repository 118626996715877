@use '@angular/material' as mat;

/* These palettes are generated using https://themes.angular-material.dev/ */
$m2-primary: (
    50 : #f7fbff,
    100 : #ebf5ff,
    200 : #deefff,
    300 : #d0e9ff,
    400 : #c6e4ff,
    500 : #bcdfff,
    600 : #b6dbff,
    700 : #add7ff,
    800 : #a5d2ff,
    900 : #97caff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$m2-accent: (
    50 : #f9f5ec,
    100 : #f0e5cf,
    200 : #e6d4b0,
    300 : #dcc390,
    400 : #d5b678,
    500 : #cda960,
    600 : #c8a258,
    700 : #c1984e,
    800 : #ba8f44,
    900 : #ae7e33,
    A100 : #ffffff,
    A200 : #fffffe,
    A400 : #ffe3cb,
    A700 : #ffd5b2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$m2-error: (
    50 : #fff6f5,
    100 : #ffe9e6,
    200 : #ffdad5,
    300 : #ffcbc4,
    400 : #ffbfb8,
    500 : #ffb4ab,
    600 : #ffada4,
    700 : #ffa49a,
    800 : #ff9c91,
    900 : #ff8c80,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



$my-primary: mat.m2-define-palette($m2-primary);
$my-accent: mat.m2-define-palette($m2-accent, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.m2-define-palette($m2-error);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

$my-dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
  )
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

@include mat.all-component-themes($my-theme);

.dark {
  @include mat.all-component-colors($my-dark-theme);
}

.mat-mdc-outlined-button:not(:disabled) {
    --mdc-outlined-button-label-text-color: var(--black);
    --mdc-outlined-button-outline-color: var(--black);
}